import { 
    merge_cart, 
    clear_cart, 
    get_cart, 
    set_transaction, 
    get_transaction
} from 'booking-widget/v2/common/data';
import { 
    load as load_search, 
    to_url_params as to_search_url_params
} from 'booking-widget/v2/pages/search';
import { 
    load as load_tours, 
    to_url_params as to_tours_url_params,
    has_required_params as has_required_tours_params
} from 'booking-widget/v2/pages/tours';
import { 
    load as load_waitlist, 
    to_url_params as to_waitlist_url_params
} from 'booking-widget/v2/pages/waitlist';
import { 
    load as load_info, 
    to_url_params as to_info_url_params,
    reload_cache as reload_info_cache,
    has_required_params as has_required_info_params
} from 'booking-widget/v2/pages/info';
import { 
    load as load_payment, 
    has_required_params as has_required_payment_params 
} from 'booking-widget/v2/pages/payment';
import { 
    load as load_confirmed
} from 'booking-widget/v2/pages/confirmed';
import { 
    setup as setup_gc
} from 'booking-widget/v2/widgets/gc-button';
import { get_scrollbar_width } from 'booking-widget/v2/common/util';


import { load_js } from 'utils/load';
import { unparams } from 'utils/urls';
import { get as get_cache, cache } from 'utils/cache';
import { is_valid_date } from 'utils/date';
import { 
    show_fullscreen_overlay,
    hide_fullscreen_overlay
} from 'ui/visibility';

let curr_step = '';
let observer;

const url_mapping = {
    is_charter: 'c',
    vessel_type: 'v',
    is_gift: 'g',
    amount_only: 'ao',
    amount_only_taxes: 'aot',
    amount_only_fees: 'aof',
};

export const STEPS = {
    search: {
        name: 'search',
        href: function() {
            let cached_params = get_cart();
            cached_params = cached_params ? cached_params : {};
            return '#!' + to_shorten_params(to_search_url_params(cached_params));
        },
    },
    tours: {
        name: 'tours',
        href: function() {
            let cached_params = get_cart();
            cached_params = cached_params ? cached_params : {};
            const url_params = to_tours_url_params(cached_params);
            if (!url_params) {
                return '';
            }

            return '#!' + to_shorten_params(url_params);
        }
    },
    waitlist: {
        name: 'waitlist',
        href: function(search_params) {
            if ($.isEmptyObject(search_params)) {
                return '';
            }
            const url_params = to_waitlist_url_params(search_params);
            if (!url_params) {
                return '';
            }

            return '#!' + to_shorten_params(url_params);
        },
    },
    info: {
        name: 'info',
        href: function() {
            let cached_params = get_cart();
            cached_params = cached_params ? cached_params : {};
            const url_params = to_info_url_params(cached_params);
            if (!url_params) {
                return '';
            }

            return '#!' + to_shorten_params(url_params);
        }
    },
    payment: {
        name: 'payment',
        href: function() {
            let cached_params = get_cart();
            cached_params = cached_params ? cached_params : {};
            if (!has_required_payment_params(cached_params)) {
                return '';
            }

            return '#!step=payment';
        }
    },
    confirmed: {
        name: 'confirmed',
        href: (() => ''),
    },
}

export const load = (opts) => {
    stop_observe_header();
    
    if (!opts.step && opts.popup_onload == true) {
        opts.step = STEPS.tours.name;
    }
    curr_step = opts.step || STEPS.search.name;
    let cart = get_cart();
    if (curr_step == STEPS.search.name) {
        let opts_with_cart = $.extend({}, (cart ? cart : {}), opts);
        const params = {
            on_loaded: function() {
                opts.on_search_step_loaded();
            },
            on_submit: function(data) {
                merge_cart(data);
                opts.on_searched(event_obj(data));
                // pass on the data
                let next_step = { step: STEPS.tours.name };
                let url_data = $.extend({}, data, next_step);
                let opts_data = $.extend({}, opts, url_data);
                
                // push state
                if (history.pushState) {
                    history.pushState(null, null, '#!' + to_shorten_params(url_data));
                }

                load(opts_data);
            }
        };
        load_search(opts_with_cart, params);
        if (opts.search_selector_compact) {
            load_search($.extend({}, opts_with_cart, {
                search_selector: opts.search_selector_compact,
                theme: 'bw-compact',
                identifier: 'bw-compact',
            }), params);
        }
    }
    else if (curr_step == STEPS.tours.name) {
        load_tours(opts, {
            on_loaded: function(data) {
                opts.on_tours_step_loaded(event_obj(data));
                observe_header($('.bw-tours .bw-header'));
            },
            on_change: function(data) {
                merge_cart(data);
                
                // pass on the data
                let curr_step = { step: STEPS.tours.name };
                let url_data = $.extend({}, data, curr_step);
                if (history.replaceState) {
                    history.replaceState(null, null, '#!' + to_shorten_params(url_data));
                }
            },
            on_reload: function(data) {
                merge_cart(data);
                // pass on the data
                let next_step = { step: STEPS.tours.name };
                let url_data = $.extend({}, data, next_step);
                let opts_data = $.extend({}, opts, url_data);
                
                // push state
                if (history.pushState) {
                    history.pushState(null, null, '#!' + to_shorten_params(url_data));
                }

                load(opts_data);

            },
            on_submit: function(data, submit_btn_el) {
                // pass on the data
                merge_cart(data);
                opts.on_trip_selected(event_obj(data));

                let next_step = { step: STEPS.info.name };
                let url_data = $.extend({}, data, next_step);
                let opts_data = $.extend({}, opts, url_data);

                if (history.pushState) {
                    history.pushState(null, null, '#!' + to_shorten_params(url_data));
                }

                load(opts_data);
            },
        });
    }
    else if (curr_step == STEPS.waitlist.name) {
        load_int_tel_input();
        load_waitlist(opts, {
            on_loaded: function(data) {
                opts.on_waitlist_step_loaded(event_obj(data));
                observe_header($('.bw-waitlist .bw-header'));
            },
            on_waitlist_submit: function(data) {
                opts.on_waitlist_submit(event_obj(data));
            },
        });
    }
    else if (curr_step == STEPS.info.name) {
        // url and opts override saved cart values
        let opts_with_cart = $.extend({}, (cart ? cart : {}), opts);
        load_int_tel_input();
        load_info(opts_with_cart, {
            on_loaded: function(data) {
                opts.on_info_step_loaded(event_obj(data));
                observe_header($('.bw-info .bw-header'));
            },
            on_change: function(data) {
                merge_cart(data);
                
                let curr_step = { step: STEPS.info.name };
                let url_data = $.extend({}, get_cart(), curr_step);
                let opts_data = $.extend({}, opts, url_data);
                
                if (history.replaceState) {
                    history.replaceState(null, null, STEPS.info.href());
                }
                load(opts_data);
            },
            on_submit: function(data) {
                merge_cart(data);

                opts.on_info_submit(event_obj(data));
                // pass on the data, but not to the url
                let url_data = { step: STEPS.payment.name };
                let opts_data = $.extend({}, opts, data, url_data);
                
                // push state
                if (history.pushState) {
                    history.pushState(null, null, '#!' + $.param(url_data));
                }

                load(opts_data);
            },
        });
    }
    else if (curr_step == STEPS.payment.name) {
        load_int_tel_input();
        // url and opts override saved cart values
        let opts_with_cart = $.extend(true, {}, (cart ? cart : {}), opts);

        // clear any unneeded vars from cart that will interfere with purchase
        if (opts_with_cart.amount_only) {
            // purchasing gc
            opts_with_cart.gift_certificate_id = null;
            opts_with_cart.reservation_id = null;
            merge_cart({
                gift_certificate_id: null,
                reservation_id: null
            });
        }
        else {
            opts_with_cart.amount_only = null;
            opts_with_cart.amount_only_fees = null;
            opts_with_cart.amount_only_taxes = null;
            merge_cart({
                amount_only: null,
                amount_only_fees: null,
                amount_only_taxes: null
            });
        }

        load_payment(opts_with_cart, {
            on_loaded: function(data) {
                opts.on_payment_step_loaded(event_obj(data));
                observe_header($('.bw-info .bw-header'));
            },
            on_payment_submit: function(data) {
                opts.on_payment_submit(event_obj(data));
            },
            on_success: function(data) {
                // save transaction info
                set_transaction(data);
                opts.on_booked(event_obj(data));
                clear_cart();
                
                let url_data = { step: STEPS.confirmed.name };
                let opts_data = $.extend({}, opts, data, url_data);

                // push state
                if (history.pushState) {
                    history.pushState(null, null, '#!' + $.param(url_data));
                }

                load(opts_data);
            },
        });
    }
    else if (curr_step == STEPS.confirmed.name) {
        let opts_with_cart = $.extend({}, opts, (!opts.contact_id ? get_transaction() : {}) );
        load_confirmed(opts_with_cart, {
            on_loaded: function() {
                observe_header($('.bw-confirmed .bw-header'));
            }
        });
    }
}

export const is_showing_full_screen = () => {
    return (curr_step != '' && curr_step != STEPS.search.name);
}

export const setup_listeners = (options) => {
    window.addEventListener('popstate', function(e) {
        let opts = merge_options_with_url(options);
        load(opts);
    });

    if (options.handle_gc) {
        let opts = merge_options_with_url(options);
        setup_gc(opts, {
            on_loaded: function(data) {
                opts.on_gc_loaded(event_obj(data));
            },
            on_submit: function(data) {
                merge_cart(data);
                opts.on_gc_submitted(event_obj(data));

                let next_step = { step: STEPS.payment.name, is_gift: 1 };
                let url_data = $.extend({}, data, next_step);
                let opts_data = $.extend({}, opts, url_data);
                
                // push state
                if (history.pushState) {
                    history.pushState(null, null, '#!' + to_shorten_params(url_data));
                }

                load(opts_data);
            },
        });
    }
}

export const merge_options_with_url = (options) => {
    let url_opts = setup_from_url();
    return $.extend({}, options, url_opts);
}

// set the pickers based on the url params, pickers need to be added to dom first.
function setup_from_url(url_hash) {
    let hash = url_hash || window.location.hash;
    let params = to_original_params(hash.replace(/^#!/, ''));
    let valid_search_params = {};
    if (params.start_time) {
        if (is_valid_date(new Date(params.start_time))) {
            valid_search_params.start_time = params.start_time;
        }
    }
    
    if (params.adult && (parseInt(params.adult) || false) !== false ) {
        valid_search_params.adult = parseInt(params.adult);
    }
    if (params.child && (parseInt(params.child) || false) !== false ) {
        valid_search_params.child = parseInt(params.child);
    }
    if (params.toddler && (parseInt(params.toddler) || false) !== false ) {
        valid_search_params.toddler = parseInt(params.toddler);
    }
    if (params.trip_id && (parseInt(params.trip_id) || false) !== false ) {
        valid_search_params.trip_id = parseInt(params.trip_id);
    }
    if (params.tour_id && (parseInt(params.tour_id) || false) !== false ) {
        valid_search_params.tour_id = parseInt(params.tour_id);
    }
    if (params.filter_tour_id && (parseInt(params.filter_tour_id) || false) !== false ) {
        valid_search_params.filter_tour_id = parseInt(params.filter_tour_id);
    }
    if (params.step && (params.step in STEPS)) {
        valid_search_params.step = params.step;
    }
    if (params.is_charter && (parseInt(params.is_charter) || false) !== false ) {
        valid_search_params.is_charter = parseInt(params.is_charter);
    }
    if (params.vessel_type && (parseInt(params.vessel_type) || false) !== false ) {
        valid_search_params.vessel_type = parseInt(params.vessel_type);
    }
    valid_search_params.is_gift = (params.is_gift == 1);
    if (params.amount_only) {
        valid_search_params.amount_only = Number(params.amount_only);
    }
    if (params.amount_only_taxes) {
        valid_search_params.amount_only_taxes = Number(params.amount_only_taxes);
    }
    if (params.amount_only_fees) {
        valid_search_params.amount_only_fees = Number(params.amount_only_fees);
    }

    let query_params = unparams(window.location.search.replace('?', ''));
    if ('book' in params || 'book' in query_params) {
        valid_search_params.popup_onload = true;
        if (params.book == 1 || query_params.book == 1) {
            Cookies.set('ca-allow-late', 1, { sameSite: 'strict' });
            valid_search_params.allow_late = true;
        }
    }
    if ('popup_onload' in params) {
        valid_search_params.popup_onload = params.popup_onload;
    }

    return valid_search_params;
}

function to_shorten_params(params) {
    const result = {};
    for (const [key, value] of Object.entries(params)) {
        const newKey = url_mapping[key] || key;
        result[newKey] = value;
    }

    return $.param(result);
}

function to_original_params(_params) {
    const params = unparams(_params)
    const flipped = {};
    for (const [key, value] of Object.entries(url_mapping)) {
        flipped[value] = key;
    }

    const result = {};
    for (const [key, value] of Object.entries(params)) {
        const newKey = flipped[key] || key;
        result[newKey] = value;
    }

    return result;
}

export const load_prev_step = (data) => {
    let url = '';
    let step_data = {};
    let curr_step_name = data.step ? data.step : '';
    let prev_step_name = '';
    if (curr_step_name == STEPS.payment.name) {
        prev_step_name = STEPS.info.name;
    }
    else if (curr_step_name == STEPS.info.name) {
        prev_step_name = STEPS.tours.name;
    }

    // load the latest compatible step
    switch(prev_step_name) {
        case STEPS.payment.name:
            // payment page is the last page, nothing to do here.
        case STEPS.info.name:
            if (has_required_info_params(data)) {
                let next_step = { step: STEPS.info.name };
                step_data = $.extend({}, data, next_step);
                url = '#!' + $.param(to_info_url_params(step_data));
                break;
            }
        case STEPS.tours.name:
            if (has_required_tours_params(data)) {
                let next_step = { step: STEPS.tours.name };
                step_data = $.extend({}, data, next_step);
                url = '#!' + $.param(to_tours_url_params(step_data));
                break;
            }
        default:
            let next_step = { step: STEPS.search.name };
            step_data = $.extend({}, data, next_step);
            url = '#!' + $.param(to_search_url_params(step_data))
            break;
    }
    
    if (history.replaceState) {
        history.replaceState(null, null, url);
    }

    load(step_data);
}

export const startOver = () => {
    navigate(window.location.pathname + window.location.search);
    $('body').removeClass('bw--server-notice');
}

// used to simulate navigation to a url.  loads options from url.
export const navigate = (url) => {
    history.pushState(null, null, url);
    load(merge_options_with_url(get_cache('options')));
}

export const show_fullscreen_modal = (context_el) => {
    let container_el = $('.booking-widget-container', context_el);
    let bw_el = $('.booking-widget', container_el);
    if (container_el.length == 0) {
        context_el.append('<div class="booking-widget-container"><div class="booking-widget"></div></div>');
        container_el = $('.booking-widget-container', context_el);
        bw_el = $('.booking-widget', container_el);
    }

    bw_el.empty();
    container_el.show();
    show_fullscreen_overlay(context_el);

    return bw_el;
}

export const hide_fullscreen_modal = (context_el) => {
    let container_el = $('.booking-widget-container', context_el);
    let bw_el = $('.booking-widget', container_el);
    if (bw_el.length != 0) {
        bw_el.empty();
        container_el.hide();
    }

    hide_fullscreen_overlay(context_el);

    return bw_el;
}

// normalize obj that is returned in events.
function event_obj(data) {
    let obj = get_cart();
    if (!obj) {
        obj = {};
        if (data.adult) {
            obj.adult = Number(data.adult);
        }
        if (data.child) {
            obj.child = Number(data.child);
        }
        if (data.toddler) {
            obj.toddler = Number(data.toddler);
        }
    }
    if (data.tour) {
        obj.tour_name = data.tour.name;
        obj.tour_id = data.tour.id;
    }
    let properties_to_copy = [
        'food_preference', 
        'is_gift', 
        'payment_amount', 
        'prices', 
        'reservation_id', 
        'start_time', 
        'tour_id', 
        'trip_id'
    ];
    for (const property of properties_to_copy) {
        if (property in data) {
            obj[property] = data[property];
        }
    }

    // if toddler, redo child count
    if (obj.toddler) {
        let child = 0;
        if (obj.child) {
            child = Number(obj.child);
        }
        obj.child = child + Number(obj.toddler);
        delete obj.toddler;
    }
    if (obj.tour_id && !obj.tour_name) {
        let tour = get_cache('tour-' + obj.tour_id);
        if (tour) {
            obj.tour_name = tour.name;
        }
        else {
            // look in tours cache
            let tours = get_cache('tours');
            if (tours && tours.data) {
                let tour = tours.data.find(function(t) {
                    return t.id == obj.tour_id;
                });
                obj.tour_name = tour.name;
            }
        }
    }

    return obj;
}

function load_int_tel_input() {
    if (window.intlTelInput) {
        return;
    }
    load_js({
        url: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/intlTelInput.min.js',
        on_ready: function() {
            $(document).trigger('bw-intltelinput-loaded');
        }
    });
    const url = 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/css/intlTelInput.css';
    $('head').append('<link rel="stylesheet" id="intl-tel-input" href="' + url + '" type="text/css" />')
}

function observe_header(btn_header_el) {
    const class_name = 'bw-scrolled';
    let btn_header = btn_header_el ? btn_header_el[0] : document.getElementsByClassName('bw-header');
    const onIntersection = (entries) => {
        entries.forEach(entry => {
            if(entry.intersectionRatio != 1) {
                if (btn_header.classList && !btn_header.classList.contains(class_name)) {
                    btn_header.classList.add(class_name);
                }
            }
            else {
                if (btn_header.classList && btn_header.classList.contains(class_name)) {
                    btn_header.classList.remove(class_name);
                }
            }
        });
    };

    observer = new IntersectionObserver(onIntersection);
    document.querySelectorAll('.bw-header--trigger').forEach(element => {
        observer.observe(element);
    });
    return 
}
function stop_observe_header() {
    if (observer) {
        observer.disconnect();
    }
}

let windowResizeTimeout = null;

$(window).resize(function() {
    if (windowResizeTimeout) {
        clearTimeout(windowResizeTimeout);
    }
    windowResizeTimeout = setTimeout(windowResized, 500);
});

function windowResized() {
    if ($(window).outerWidth() < 768) {
        return;
    }
    document.documentElement.style.setProperty('--bw-scrollbar-width', get_scrollbar_width() + 'px');
};
